<sb-dialog [title]="data.title">
  <ng-template #content>
    <sb-text [innerHTML]="data.content" noMargin />
  </ng-template>
  <ng-template #actions>
    <sb-button variant="text" (click)="onCancel()">
      {{ data.cancel || ("words.cancel" | transloco | titlecase) }}
    </sb-button>
    <sb-button (click)="onConfirm()">
      {{ data.confirm || ("words.confirm" | transloco | titlecase) }}
    </sb-button>
  </ng-template>
</sb-dialog>
